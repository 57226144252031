
.case-writing-page-view-page{

    .case-writing-page-view-page-header{
       /* height: 160px; */
       /* border: 1px solid rgb(116, 109, 109); */
       margin-bottom: 30px;
      /*  margin-top: 30px; */
       .case-writing-page-view-page-header-top-style{
         width: 100%;
         height: 50px;  
        background-color: #BE9D40;
        clip-path: polygon(100% 0, 100% 100%, 76% 37%, 61% 10%, 50% 0, 35% 13%, 18% 46%, 0 100%, 0 0);

       }
    }
    .case-writing-page-view-page-header-content-left p{
        color: #BE9D40 !important;
    }
    .case-writing-page-view-page-header-content-right p{
        color: #BE9D40 !important;
    }
    .case-writing-page-view-page-header-content{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -30px;
        .case-writing-page-view-page-header-content-center img{
            width: 150px;
        }
    }
  
        .case-writing-page-view-page-footer{
            width: 100%;
            background-color: white;
            margin-top: 140px;
            .icon-back-letter-pad{
                background-color: #BE9D40;
                color: white;
                padding: 4px 2px 4px 4px;
                border-radius: 5px;  
                font-size: 16px; 

                
            }
            .case-writing-page-view-page-footer-bottom-style{
                width: 100%;
                height: 50px;  
                margin-top: 10px;
               background-color: #BE9D40;
               clip-path: polygon(42% 79%, 46% 85%, 50% 93%, 58% 82%, 84% 33%, 100% 0, 100% 100%, 0 100%, 0 0, 39% 75%);
            
            }
        }
      
      
      @media print {
      .case-writing-page-view-page-footer{
        position: fixed;
        bottom: 10px;
 /*        background-color: white;
        padding: 15px 0px 0px 0px; */
      
    }
}
  
}
/* .page-break {
    margin-bottom: 20mm;
    display: block;
    page-break-before: auto;
  } */
  .header, .header-space,
.footer, .footer-space {
  height: 150px;
}
.header {
  position: fixed;
  top: 0;
}
.footer {
  position: fixed;
  bottom: 0;
  margin-top: 40px;
}
#print-content{
    display: table;
}
/* @media print { */
    #pageFooter-content{
        margin-top: 20px;
        margin-bottom: -40px;
 
    }
    #pageFooter-content::before{
    counter-reset:page
    }
    @page {
        margin: 0px;
      }
    @media print{
        @page :left {
            @bottom-left {
              content: counter(page);
           }
        }
        @page {
          size: A4 portrait;
          margin: 0px;
        }
        .pageBreak {
            page-break-before: always;
        }
        #pageFooter-content::after{
   
        
            counter-increment: page; 
              content: "Page " counter(page) ;
              
             
            margin-top: 20px;
            margin-bottom: -40px;
           /*  position: fixed;
            right: 20px; 
            bottom: 200px;
            white-space: nowrap; 
            z-index: 20;
            -moz-border-radius: 5px; 
            -moz-box-shadow: 0px 0px 4px #222;  
            background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);  */
        }
    }

    @page :left {
        @bottom-left {
          content: counter(page);
       }
    }