.container-custom{
    max-width: 1350px;
    align-items: center;
    align-self: center !important;
    margin: 0 auto;
    
}

#counselling-table{
    margin-top: 20px;
    }
    #counselling-table {
        border-collapse: collapse;
        width: 100%;
      }
      
      #counselling-table td, #counselling-table th {
      margin-top: 5px;
      border-top: 4px solid white;
        padding: 8px;
      }
      #counselling-table td img{
          width: 20px; height: 20px;
          border-radius: 50%;
          margin-right: 5px;
      }
      
      #counselling-table tr:nth-child(even){background-color: #f2f2f2;}
      #counselling-table tr:nth-child(odd){background-color: #f2f2f2;}
      #counselling-table tr:hover {background-color: #ddd;}
      
      #counselling-table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
      
        color:#666666;
      }
    
      .quotaion-table-pagination-area{
        margin: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap:wrap-reverse;
        font-size: 14px;
        color: #666666;
    }
    .pagination-table-quotaion-dec{
        border: 1px solid #666666;
        padding: 0px 10px 0px 10px;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
    }
    .pagination-table-quotaion-dec i{
    cursor: pointer;
    }
    .pagination-table-quotaion-inc i{
        cursor: pointer;
        }
        
    .pagination-table-quotaion-inc{
        border: 1px solid #666666;
        border-left: transparent !important;
        padding: 0px 10px 0px 10px;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px; 
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        border:1px solid white !important 
    }
    .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        box-shadow: 0 0 0 2px rgb(255,255,255,1) !important;
    
    }
    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: 0 0 0 2px rgb(255,255,255,1) !important;   
    }
    @media (max-width:7000px) and (min-width:575px){
        .search_input_width_file_rep{
   min-width: 280px !important;
        }
    }
    @media (max-width:575px) and (min-width:0px){
        .search_input_width_file_rep{
            min-width: 240px !important;
        }
    }
    .session-table-area-receptionist{
        overflow-x: auto;
        margin-top: 20px;
      /*   direction: rtl; */
    }
    .case-overview-items-receptionist{
        height: 120px;
        width: 100%;
        background-color: white;
        -webkit-box-shadow: -1px 2px 10px 0px rgba(214,207,214,1);
        -moz-box-shadow: -1px 2px 10px 0px rgba(214,207,214,1);
        box-shadow: -1px 2px 10px 0px rgba(214,207,214,1);
        display: flex;
        justify-content: center;
    align-items: center;
    }
    .case-overview-items-area{
        padding: 15px;
    }
    .case-overview-items-receptionist-right{
        margin-left: 18px;
    }
    .case-overview-items-receptionist-right center span{
        font-size: 24px;
        font-weight: 700;
    }
    .case-overview-items-receptionist-right p{
color: rgb(175, 171, 171);
margin-bottom: 0px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #666666;
        font-weight: 550;
    }
    .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn:hover{
        color: #666666;
        font-weight: 550;
    }
    .ant-tabs{
        color: #666666;
        font-weight: 550;
    }
    .case-writing-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;
    }
    .case-writing-header p{
        font-size: 20px;
        margin-bottom: 5px;
    }
    .case-writing-editor-area{
        border: 1px solid lightgrey;
        min-height: 500px;
        position: relative;
        padding: 10px 20px;
    }
    .button-of-case-writing-arae{
        position: absolute;
        bottom: 20px;
        right: 20px;
        margin-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ._1ceqH ._1Yplu{
        position: absolute !important;
        right: -20px !important;
        bottom: -13px !important;
    }
    ._1ceqH ._1lB9c{
    background-color: #BE9D40 !important;
    color: white !important;
    }
    ._1ceqH ._1lB9c ._3-aC9{
        display: none !important; 
    }